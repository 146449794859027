// 降雨量表格list
export const fallList = [
  {
    prop: "rainHour8",
    label: "8时",
  },
  {
    prop: "rainHour9",
    label: "9时",
  },
  {
    prop: "rainHour10",
    label: "10时",
  },
  {
    prop: "rainHour11",
    label: "11时",
  },
  {
    prop: "rainHour12",
    label: "12时",
  },
  {
    prop: "rainHour13",
    label: "13时",
  },
  {
    prop: "rainHour14",
    label: "14时",
  },
  {
    prop: "rainHour15",
    label: "15时",
  },
  {
    prop: "rainHour16",
    label: "16时",
  },
  {
    prop: "rainHour17",
    label: "17时",
  },
  {
    prop: "rainHour18",
    label: "18时",
  },
  {
    prop: "rainHour19",
    label: "19时",
  },
  {
    prop: "rainHour20",
    label: "20时",
  },
  {
    prop: "rainHour21",
    label: "21时",
  },
  {
    prop: "rainHour22",
    label: "22时",
  },
  {
    prop: "rainHour23",
    label: "23时",
  },
  {
    prop: "rainHour0",
    label: "0时",
  },
  {
    prop: "rainHour1",
    label: "1时",
  },
  {
    prop: "rainHour2",
    label: "2时",
  },
  {
    prop: "rainHour3",
    label: "3时",
  },
  {
    prop: "rainHour4",
    label: "4时",
  },
  {
    prop: "rainHour5",
    label: "5时",
  },
  {
    prop: "rainHour6",
    label: "6时",
  },
  {
    prop: "rainHour7",
    label: "7时",
  },
];

// 日降雨量表格list
export const dayList = [
  {
    prop: "rainHour8",
    label: "8时",
  },
  {
    prop: "rainHour9",
    label: "9时",
  },
  {
    prop: "rainHour10",
    label: "10时",
  },
  {
    prop: "rainHour11",
    label: "11时",
  },
  {
    prop: "rainHour12",
    label: "12时",
  },
  {
    prop: "rainHour13",
    label: "13时",
  },
  {
    prop: "rainHour14",
    label: "14时",
  },
  {
    prop: "rainHour15",
    label: "15时",
  },
  {
    prop: "rainHour16",
    label: "16时",
  },
  {
    prop: "rainHour17",
    label: "17时",
  },
  {
    prop: "rainHour18",
    label: "18时",
  },
  {
    prop: "rainHour19",
    label: "19时",
  },
  {
    prop: "rainHour20",
    label: "20时",
  },
  {
    prop: "rainHour21",
    label: "21时",
  },
  {
    prop: "rainHour22",
    label: "22时",
  },
  {
    prop: "rainHour23",
    label: "23时",
  },
  {
    prop: "rainHour0",
    label: "0时",
  },
  {
    prop: "rainHour1",
    label: "1时",
  },
  {
    prop: "rainHour2",
    label: "2时",
  },
  {
    prop: "rainHour3",
    label: "3时",
  },
  {
    prop: "rainHour4",
    label: "4时",
  },
  {
    prop: "rainHour5",
    label: "5时",
  },
  {
    prop: "rainHour6",
    label: "6时",
  },
  {
    prop: "rainHour7",
    label: "7时",
  },
];

// 月降雨量表格list
export const monthList = [
  {
    prop: "rainDay1",
    label: "1日",
  },
  {
    prop: "rainDay2",
    label: "2日",
  },
  {
    prop: "rainDay3",
    label: "3日",
  },
  {
    prop: "rainDay4",
    label: "4日",
  },
  {
    prop: "rainDay5",
    label: "5日",
  },
  {
    prop: "rainDay6",
    label: "6日",
  },
  {
    prop: "rainDay7",
    label: "7日",
  },
  {
    prop: "rainDay8",
    label: "9日",
  },
  {
    prop: "rainDay10",
    label: "10日",
  },
  {
    prop: "rainDay11",
    label: "11日",
  },
  {
    prop: "rainDay12",
    label: "12日",
  },
  {
    prop: "rainDay13",
    label: "13日",
  },

  {
    prop: "rainDay14",
    label: "14日",
  },
  {
    prop: "rainDay15",
    label: "15日",
  },
  {
    prop: "rainDay16",
    label: "16日",
  },
  {
    prop: "rainDay17",
    label: "17日",
  },
  {
    prop: "rainDay18",
    label: "18日",
  },
  {
    prop: "rainDay19",
    label: "19日",
  },
  {
    prop: "rainDay20",
    label: "20日",
  },
  {
    prop: "rainDay21",
    label: "21日",
  },
  {
    prop: "rainDay22",
    label: "22日",
  },
  {
    prop: "rainDay23",
    label: "23日",
  },
  {
    prop: "rainDay24",
    label: "24日",
  },
  {
    prop: "rainDay25",
    label: "25日",
  },
  {
    prop: "rainDay26",
    label: "26日",
  },
  {
    prop: "rainDay27",
    label: "27日",
  },
  {
    prop: "rainDay28",
    label: "28日",
  },
  {
    prop: "rainDay29",
    label: "29日",
  },
  {
    prop: "rainDay30",
    label: "30日",
  },
  {
    prop: "rainDay31",
    label: "31日",
  },
];

// 月降雨量表格list
export const yearList = [
  {
    prop: "rainMonth1",
    label: "1月",
  },
  {
    prop: "rainMonth2",
    label: "2月",
  },
  {
    prop: "rainMonth3",
    label: "3月",
  },
  {
    prop: "rainMonth4",
    label: "4月",
  },
  {
    prop: "rainMonth5",
    label: "5月",
  },
  {
    prop: "rainMonth6",
    label: "6月",
  },
  {
    prop: "rainMonth7",
    label: "7月",
  },
  {
    prop: "rainMonth8",
    label: "8月",
  },
  {
    prop: "rainMonth9",
    label: "9月",
  },
  {
    prop: "rainMonth10",
    label: "10月",
  },
  {
    prop: "rainMonth11",
    label: "11月",
  },
  {
    prop: "rainMonth12",
    label: "12月",
  },
];
