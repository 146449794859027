<!-- 测流点管理 -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <span style="color: #606266;padding-left: 10px">测流点：</span>
        <el-select v-model="searchValue.devName" placeholder="请选择测流点" size="mini">
          <el-option v-for="(item,index) in waterList" :key="item.deviceAddress" :label="index+1+' '+item.devName"
            :value="item.devName">
          </el-option>
        </el-select>
        <!-- <el-input
          placeholder="请输入拦河闸名称"
          v-model="searchValue.devName"
        ></el-input> -->
        <span style="color: #606266;padding-left: 10px;padding-right: 10px">时间：</span>
        <el-date-picker :editable="false" v-model="searchValue.queryTime" type="date" placeholder="选择日期"
          class="check_date" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
    </div>
    <div>
      <el-table :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }" :data="tableData" stripe style="width: 100%" v-loading="loading" element-loading-text="数据量过大，加载中...">
        <el-table-column type="index" prop="sid" label="序号" width="95"></el-table-column>
        <el-table-column prop="devName" label="测流点名称"> </el-table-column>
        <el-table-column prop="speed" label="流速(m/s)"> </el-table-column>
        <el-table-column prop="waterLevel" label="水位(cm)"> </el-table-column>
        <el-table-column prop="insFlow" label="瞬时流量(m³/h)"> </el-table-column>
        <el-table-column prop="sumFlow" label="累计流量(m³)"> </el-table-column>
        <el-table-column prop="dataTime" label="采集时间"> </el-table-column>
        <!-- <el-table-column  width="200" label="统计">
           <template slot-scope="scope">
            <el-button class="edit" @click="handleEdit(scope.row,'2')"
              >月度</el-button
            >
            <el-button class="edit" @click="handleEdit(scope.row,'1')"
              >年度</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination background layout="total, prev, pager, next, jumper" :total="pagination.total"
        @current-change="handleCurrent" :current-page="pagination.pageNum">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog class="border-r-8" style="overflow: hidden;" :visible.sync="addShow" width="calc(1300/1920*100vw)"
      @close="resetForm('ruleForm')">
      <div class="main_box" style="overflow: hidden;margin:0 20px">
        <div class="nav_menu">
          <div class="menu_left">
            <!-- <el-input
              placeholder="请输入拦河闸名称"
              v-model="monthInfo.value"
            ></el-input> -->
            <span class="logTitle">{{editVal.devName}}</span>
            <el-date-picker v-if="editVal.num==1" :editable="false" v-model="time" type="date" placeholder="选择年份"
              class="check_date" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
            </el-date-picker>
            <el-date-picker v-if="editVal.num==2" :editable="false" v-model="time" type="date" placeholder="选择月份"
              class="check_date" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
            </el-date-picker>
            <el-button type="primary" @click="editSearch(0)">查询</el-button>
            <el-button type="info" @click="editReset(1)">重置</el-button>
          </div>
        </div>
        <Echart :option="ChartData1" style="height:300px"></Echart>
        <div ref="scroll">
          <el-table :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }" :data="logTab" stripe style="margin-top:20px" height="300" v-loading="loading"
            element-loading-text="数据量过大，加载中...">
            <el-table-column prop="statisticsName" label="" :show-overflow-tooltip="true" width="120">
            </el-table-column>
            <el-table-column v-for="col in cols" :prop="col.prop" :label="col.label" :key="col.prop" width="90">
            </el-table-column>
          </el-table>
          <!-- <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination> -->
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { FlowMeterData } from "../../api/index";
import Echart from "../../components/Echart/Echart";
import { monthList, yearList } from "../RainCondition/index";
export default {
  components: { Echart },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
      logTab: [],
      ChartData1: null,
      addShow: false,
      time: '',
      waterList: [],//拦河闸下拉
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0
      },
      cols: monthList,
      editVal: {
        num: '',
      },
      listMonth: [],
      listDay: [],
      searchValue: {
        devName: "",
        queryTime: '',
      },
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleEdit(val, nun) {

      if (!val.dataTime) {
        console.log(val);
        this.$message.error('暂无数据！');
        return false
      }
      this.time = ''
      this.editVal = val
      this.editVal.num = nun
      let dataTimer = val.dataTime.trim().split(" ")
      if (nun == 1) {
        this.listMonth = []
        this.cols = yearList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: val.devSn,
          dateTime: dataTimer[0],
          dateType: '1',
        }).then(res => {
          // console.log(res);
          //  this.logTab = res.data
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            // console.log(item);
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listMonth.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              obj["rainMonth" + items.createMonth] = items["averageValue"];
              //  obj["sumRainfall"] = item["sumRainfall"];

              //  if(items["averageValue"]){
              //    this.listMonth.push(items["averageValue"])
              //  }
            });

            return obj;
          });
          this.ChartData1 = this.getChartData()
          this.ChartData1.series[0].data = this.listMonth
        });

      } else {
        this.listDay = []
        this.cols = monthList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: val.devSn,
          dateTime: dataTimer[0],
          dateType: '2',
        }).then(res => {
          // console.log(res)
          // this.logTab = res.data
          let year = []
          for (let i = 0; i <= this.cols.length; i++) {
            year.push(i + 1 + '日')
          }
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listDay.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              // console.log(items);
              obj["rainDay" + items.createDay] = items["averageValue"];
              // obj["sumRainfall"] = item["sumRainfall"];
            });
            return obj;
          });
          // console.log(this.listDay);
          //   this.tableData = res.data.list;
          //  this.pagination.total = res.data.total;
          //   this.pagination.pageNum = res.data.pageNum;
          let num = this.getChartData()
          num.xAxis.data = year
          num.series[0].data = this.listDay
          this.ChartData1 = num
        });

      }

      this.addShow = true
    },
    editSearch() {
      if (this.editVal.num == 1) {
        this.listMonth = []
        this.cols = yearList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: this.editVal.devSn,
          dateTime: this.time,
          dateType: this.editVal.num,
        }).then(res => {
          // console.log(res);
          //  this.logTab = res.data
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            // console.log(item);
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listMonth.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              obj["rainMonth" + items.createMonth] = items["averageValue"];
              //  obj["sumRainfall"] = item["sumRainfall"];

              //  if(items["averageValue"]){
              //    this.listMonth.push(items["averageValue"])
              //  }
            });

            return obj;
          });
          this.ChartData1 = this.getChartData()
          this.ChartData1.series[0].data = this.listMonth
        });

      } else {
        this.listDay = []
        this.cols = monthList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: this.editVal.devSn,
          dateTime: this.time,
          dateType: this.editVal.num,
        }).then(res => {
          // console.log(res)
          // this.logTab = res.data
          let year = []
          for (let i = 0; i <= this.cols.length; i++) {
            year.push(i + 1 + '号')
          }
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listDay.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              // console.log(items);
              obj["rainDay" + items.createDay] = items["averageValue"];
              // obj["sumRainfall"] = item["sumRainfall"];
            });
            return obj;
          });
          // console.log(this.listDay);
          //   this.tableData = res.data.list;
          //  this.pagination.total = res.data.total;
          //   this.pagination.pageNum = res.data.pageNum;
          let num = this.getChartData()
          num.xAxis.data = year
          num.series[0].data = this.listDay
          this.ChartData1 = num
        });

      }
    },
    // 弹框重置
    editReset() {
      let dataTimer = this.editVal.dataTime.trim().split(" ")
      this.time = ''
      if (this.editVal.num == 1) {
        this.listMonth = []
        this.cols = yearList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: this.editVal.devSn,
          dateTime: dataTimer[0],
          dateType: this.editVal.num,
        }).then(res => {
          // console.log(res);
          //  this.logTab = res.data
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            // console.log(item);
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listMonth.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              obj["rainMonth" + items.createMonth] = items["averageValue"];
              //  obj["sumRainfall"] = item["sumRainfall"];

              //  if(items["averageValue"]){
              //    this.listMonth.push(items["averageValue"])
              //  }
            });

            return obj;
          });
          this.ChartData1 = this.getChartData()
          this.ChartData1.series[0].data = this.listMonth
        });

      } else {
        this.listDay = []
        this.cols = monthList
        this.$get("/flowMeterDataStatistics/findStatistics", {
          deviceAddress: this.editVal.devSn,
          dateTime: dataTimer[0],
          dateType: this.editVal.num,
        }).then(res => {
          // console.log(res)
          // this.logTab = res.data
          let year = []
          for (let i = 0; i <= this.cols.length; i++) {
            year.push(i + 1 + '号')
          }
          this.logTab = res.data.map(item => {
            // let num = item.dayRainVos.length;
            let obj = {};
            obj.statisticsName = item.statisticsName;
            if (item.statisticsName == '水位平均值') {
              item["statisticsList"].forEach(items => {
                this.listDay.push(items["averageValue"])
              });
            }
            item["statisticsList"].forEach(items => {
              // console.log(items);
              obj["rainDay" + items.createDay] = items["averageValue"];
              // obj["sumRainfall"] = item["sumRainfall"];
            });
            return obj;
          });
          // console.log(this.listDay);
          //   this.tableData = res.data.list;
          //  this.pagination.total = res.data.total;
          //   this.pagination.pageNum = res.data.pageNum;
          let num = this.getChartData()
          num.xAxis.data = year
          num.series[0].data = this.listDay
          this.ChartData1 = num
        });

      }
    },
    resetForm() { },
    getChartData() {
      return {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
            color: '#D1D1D1'
          }
        },
        xAxis: {
          // name:"月",
          nameTextStyle: {
            fontSize: 10,
          },
          type: 'category',
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: "水位平均值(m)",//单位
          nameTextStyle: {
            fontSize: 10,
            fontSize: 14,
            fontWeight: 'bold',
            color: '#4073B6'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        tooltip: {
          trigger: 'axis',
          formatter: '水位平均值' + ' <br> {b0} ：{c0} m'
        },
        series: [
          {
            //    name: '今年',
            data: [1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1],
            type: 'bar',
            stack: 'year',
            emphasis: {
              focus: 'series'
            },
            barWidth: '57%',
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    fontSize: 12,
                    fontWeight: 'bold'
                  }
                },
                color: '#8270fc'
              }
            }
          },
        ]
      }
    },
    // 列表
    getList() {
      this.loading = true;
      let { pageNum, size } = this.pagination;
      this.$get(`/flowMeterData/page/${pageNum}/${size}`).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.loading = false;
      });
      FlowMeterData().then(res => {
        this.waterList = res.data
      })
    },
    // 分页
    handleCurrent(page) {
      this.loading = true;
      this.pagination.pageNum = page;
      this.pagination.size = 10;
      let { pageNum, size } = this.pagination;
      let { devName, queryTime } = this.searchValue;
      this.$get(`/flowMeterData/page/${pageNum}/${size}`, {
        devName,
        queryTime
      })
        .then(res => {
          // console.log(res);
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
          this.pagination.pageNum = res.data.pageNum;
        })
        .catch(res => {
          // console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    handleSearch() {
      let { devName, queryTime } = this.searchValue;
      this.$get("/flowMeterData/page/1/10", {
        devName,
        queryTime
      }).then(res => {
        // console.log(res);
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.pagination.pageNum = res.data.pageNum;
      });
      // if (this.searchValue.searhTime.length) {
      //   startTime = this.searchValue.searhTime[0];
      //   endTime = this.searchValue.searhTime[1];

      // } else {
      //   this.$get("/flowMeterData/page/1/10", {
      //     devName
      //   }).then(res => {
      //     this.tableData = res.data.list;
      //    this.pagination.total = res.data.total;
      //     this.pagination.pageNum = res.data.pageNum;
      //   });
      // }
    },
    // 重置
    handleReset() {
      this.pagination.pageNum = 1;
      this.searchValue.devName = "";
      this.searchValue.queryTime = "";
      this.getList();
    },
    handleExport() {
      let { endTime, startTime, siteName, searhTime } = this.searchValue;
      if (searhTime.length) {
        startTime = searhTime[0];
        endTime = searhTime[1];
        fetch(
          this.$axios.defaults.baseURL +
          `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            // console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "流量统计查询.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
          `gateUpRecordItem/traffic/export?endTime=${endTime}&startTime=${startTime}&siteName=${siteName}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            // console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "流量统计查询.xlsx";
            a.click();
          });
      }
    }
  },
  created() {
    this.getList();
  },
  mounted() { }
};
</script>
<style lang="scss" scoped>
::v-deep .el-range-input {
  background: #f3f4f8 !important;
}
::v-deep .el-range-separator {
  background: #f3f4f8 !important;
}
::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(265 / 1920 * 100vw) !important ;
}
.columnarChart {
  width: 100%;
  height: 230px;
  margin: 0px auto;
}

.logTitle {
  font-size: 28px;
  font-weight: bold;
  color: #4073b6;
  display: inline-block;
  padding-right: 40px;
}
</style>
